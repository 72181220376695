// userContext.js
import { useRouter } from 'next/navigation';
import { createContext, useContext, useState } from 'react';

interface CatalogContextType {
	categoriesSelected: Array<string>;
	handlerSelected: (categoryId: string) => void;
	clearSelected: () => void;
	clearSelectedAndRouter: () => void;
}
const CatalogContext = createContext<CatalogContextType>({
	categoriesSelected: [],
	handlerSelected: () => {},
	clearSelected: () => {},
	clearSelectedAndRouter: () => {},
});

export const CatalogProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [categoriesSelected, setCategoriesSelected] = useState<Array<string>>(
		[]
	);
	const router = useRouter();

	function handlerSelected(value: string) {
		const currentIndex = categoriesSelected.indexOf(value);
		const newChecked = [...categoriesSelected];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setCategoriesSelected(newChecked);
	}
	function clearSelected() {
		setCategoriesSelected([]);
	}
	function clearSelectedAndRouter() {
		setCategoriesSelected([]);
		router.push('/catalog');
	}

	return (
		<CatalogContext.Provider
			value={{
				categoriesSelected,
				handlerSelected,
				clearSelected,
				clearSelectedAndRouter,
			}}
		>
			{children}
		</CatalogContext.Provider>
	);
};

export const useCatalogContext = () => useContext(CatalogContext);

export default CatalogContext;
