'use client';
import {
	Box,
	Container,
	Grid,
	List,
	ListItem,
	Typography,
} from '@mui/material';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { ImageWithFallback } from '../ImageWithFallback';

import { useClientTranslation } from '@negotium/common';
import {
	ICategoriesShow,
	IGeneralConfigurationShow,
	IPagesBasicsShow,
	ISocialNetworksShow,
} from '@negotium/models';
import { useNotification } from '../ErrorContext';
import { getGeneralConfigurationActives } from '../../app/lib/generalConfiguration/actions';
import { getPageBasicByMultiplesTypes } from '../../app/lib/pagesBasic/actions';
import { getCategoriesParent } from '../../app/lib/categories/actions';
import { getSocialNetworksPublic } from '../../app/lib/socialNetworks/actions';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TelegramIcon from '@mui/icons-material/Telegram';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
type FooterProps = {
	lng: 'es' | 'en';
};

const ICON_SOCIAL_NETWORK: { [key: string]: JSX.Element } = {
	facebook: (
		<FacebookIcon
			sx={{
				color: 'white.main',
			}}
		/>
	),
	whatsapp: (
		<WhatsAppIcon
			sx={{
				color: 'white.main',
			}}
		/>
	),
	twitter: (
		<XIcon
			sx={{
				color: 'white.main',
			}}
		/>
	),
	instagram: (
		<InstagramIcon
			sx={{
				color: 'white.main',
			}}
		/>
	),
	youtube: (
		<YouTubeIcon
			sx={{
				color: 'white.main',
			}}
		/>
	),
	linkedin: (
		<LinkedInIcon
			sx={{
				color: 'white.main',
			}}
		/>
	),
	tiktok: (
		<PlayCircleIcon
			sx={{
				color: 'white.main',
			}}
		/>
	),
	discord: (
		<SmartToyIcon
			sx={{
				color: 'white.main',
			}}
		/>
	),
	telegram: (
		<TelegramIcon
			sx={{
				color: 'white.main',
			}}
		/>
	),
	webSite: (
		<TravelExploreIcon
			sx={{
				color: 'white.main',
			}}
		/>
	),
};

// TODO i18n

const Footer = ({ lng }: FooterProps) => {
	const { t } = useClientTranslation(lng, ['common', 'pagesBasic']);
	const { setErrorNotification } = useNotification();

	const [configGeneral, setConfigGeneral] =
		useState<IGeneralConfigurationShow | null>(null);

	const [pageBasicColumn2, setPageBasicColumn2] = useState<
		Array<IPagesBasicsShow>
	>([]);
	const [pageBasicColumn3, setPageBasicColumn3] = useState<
		Array<IPagesBasicsShow>
	>([]);

	const [categories, setCategories] = useState<Array<ICategoriesShow>>([]);
	console.log('🚀 ~ Footer ~ categories:', categories);
	const [socialNetworks, setSocialNetworks] = useState<
		Array<ISocialNetworksShow>
	>([]);

	useEffect(() => {
		Promise.all([
			getGeneralConfigurationActives(),
			getPageBasicByMultiplesTypes({
				types: ['termsShipping', 'paymentTerms', 'warrantiesRefunds', 'faqs'],
			}),
			getPageBasicByMultiplesTypes({
				types: ['privacyTerms', 'termsAndConditions'],
			}),
			getCategoriesParent(),
			getSocialNetworksPublic(),
		])
			.then(
				([
					configGeneralResponse,
					pageBasicColumn2Response,
					pageBasicColumn3Response,
					categoriesParentResponse,
					socialNetworksPublicResponse,
				]) => {
					setConfigGeneral(configGeneralResponse.docs);
					setPageBasicColumn2(pageBasicColumn2Response.docs);
					setPageBasicColumn3(pageBasicColumn3Response.docs);
					setCategories(categoriesParentResponse.docs);
					setSocialNetworks(socialNetworksPublicResponse.docs);
				}
			)
			.catch((error) => setErrorNotification(error.message));
	}, []);

	return (
		<footer>
			<Container
				maxWidth={false}
				disableGutters
				sx={{
					mt: 5,
					background: (theme) => theme.palette.primary.main,
					borderBottom: (theme) => `1px solid ${theme.palette.white?.main}`,
				}}
			>
				<Container maxWidth='xl'>
					<Grid
						container
						spacing={4}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							flexWrap: { md: 'initial', xs: 'wrap' },
						}}
					>
						<Grid item xs={12} sm={6} md={3}>
							<Box
								sx={{
									width: { xs: '70%', md: '70%' },
									padding: '5px 0px',
									display: 'flex',
									margin: {
										md: '0',
										xs: '0 auto',
									},
								}}
							>
								<Link href={`/${lng}`} hrefLang={lng}>
									<ImageWithFallback
										src='/static/logo-horizontal-white191x70.png'
										fallbackImage='/static/logo-horizontal-white191x70.png'
										alt='logo'
										width={192}
										height={70}
										style={{ width: '100%', height: 'auto' }}
									/>
								</Link>
							</Box>
							<Box>
								<Typography
									variant='BodySection'
									fontSize='1rem'
									margin='10px 0'
									lineHeight='1.5rem'
									textAlign={{ md: 'left', xs: 'center' }}
									color='white.main'
								>
									{configGeneral?.description?.[lng]}
								</Typography>
							</Box>
							<List
								sx={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									margin: '20px 0',
								}}
							>
								{socialNetworks.map((social) => (
									<ListItem
										key={social.socialNetworksId}
										alignItems='center'
										sx={{
											justifyContent: { sm: 'flex-start', xs: 'center' },
											height: '45px',
											width: 'max-content',
											marginBottom: 1,
										}}
									>
										<Link
											href={social.url}
											style={{
												textDecoration: 'none',
											}}
										>
											{ICON_SOCIAL_NETWORK[social.name]}
										</Link>
									</ListItem>
								))}
							</List>
						</Grid>
						<Grid item xs={12} sm={3}>
							<List
								sx={{
									width: '100%',
								}}
							>
								{pageBasicColumn2.map((page) => (
									<ListItem
										key={page.pagesBasicId}
										alignItems='center'
										sx={{
											width: '100%',
											justifyContent: { sm: 'flex-start', xs: 'center' },
											padding: '0px 1rem',
										}}
									>
										<Link
											href={`/information/${page.pageBasicType}`}
											style={{
												textDecoration: 'none',
											}}
										>
											<Typography
												variant='BodySection'
												fontSize='clamp(0.8rem,3vw,1rem)'
												textAlign={{ sm: 'right', xs: 'center' }}
												textTransform='uppercase'
												lineHeight='1.5rem'
												color='white.main'
												sx={{
													'&:hover': {
														textDecoration: 'underline',
													},
												}}
											>
												{t(page.pageBasicType, { ns: 'pagesBasic' })}
											</Typography>
										</Link>
									</ListItem>
								))}
							</List>
						</Grid>
						{categories.length > 0 && (
							<Grid item xs={12} sm={3}>
								<List
									sx={{
										width: '100%',
									}}
								>
									{categories.map((category) => (
										<ListItem
											key={category.categoriesId}
											alignItems='center'
											sx={{
												width: '100%',
												justifyContent: { sm: 'flex-end', xs: 'center' },
												padding: '0px 1rem',
											}}
										>
											<Link
												href={`${lng}/catalog?categoriesId=${category.categoriesId}`}
												style={{
													textDecoration: 'none',
												}}
											>
												<Typography
													variant='BodySection'
													lineHeight='1.5rem'
													fontSize='clamp(0.8rem,3vw,1rem)'
													textAlign={{ sm: 'right', xs: 'center' }}
													textTransform='uppercase'
													color='white.main'
													sx={{
														'&:hover': {
															textDecoration: 'underline',
														},
													}}
												>
													{category.name[lng]}
												</Typography>
											</Link>
										</ListItem>
									))}
								</List>
							</Grid>
						)}

						<Grid item xs={12} sm={3}>
							<List
								sx={{
									width: '100%',
								}}
							>
								{pageBasicColumn3.map((page) => (
									<ListItem
										key={page.pagesBasicId}
										alignItems='center'
										sx={{
											width: '100%',
											justifyContent: { sm: 'flex-end', xs: 'center' },
											padding: '0px 1rem',
										}}
									>
										<Link
											href={`/information/${page.pageBasicType}`}
											style={{
												textDecoration: 'none',
											}}
										>
											<Typography
												variant='BodySection'
												lineHeight='1.5rem'
												fontSize='clamp(0.8rem,3vw,1rem)'
												textAlign={{ sm: 'right', xs: 'center' }}
												textTransform='uppercase'
												color='white.main'
												sx={{
													'&:hover': {
														textDecoration: 'underline',
													},
												}}
											>
												{t(page.pageBasicType, { ns: 'pagesBasic' })}
											</Typography>
										</Link>
									</ListItem>
								))}
							</List>
						</Grid>
					</Grid>
				</Container>
			</Container>
			<Container
				maxWidth={false}
				sx={{
					py: 2,
					background: (theme) => theme.palette.primary.main,
				}}
			>
				<Typography
					variant='BodySection'
					fontSize='clamp(0.9rem,3vw,1.1rem)'
					textAlign='center'
					color='white.main'
					lineHeight={{
						xs: '1.6rem',
						md: '2.6rem',
					}}
					sx={{
						a: {
							color: (theme) => theme.palette.white?.main,
						},
					}}
				>
					<span>{` ${t('allRights')} _ © ${new Date().getFullYear()} _ ${t(
						'developBy'
					)} `}</span>
					<Link
						href='https://www.futurasit.com'
						passHref
						style={{
							textDecoration: 'none',
						}}
					>
						Futura Sit
					</Link>
				</Typography>
			</Container>
		</footer>
	);
};

export default Footer;
