'use client';
import {
	Box,
	Button,
	Chip,
	Drawer,
	Fab,
	Grid,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useClientTranslation, useSessionClient } from '@negotium/common';
import { CartShopProvider, useContextCartShop } from '../ContextCartShop';
import { getTotalProductsOfCartShop } from '@negotium/libCustomer';
import { useNotification } from '../ErrorContext';
import { useRouter } from 'next/navigation';
import { ProductsOfCart } from '../ProductsOfCart';
import { useContextGeneral } from '../ContextGeneral';
import { Close } from '@mui/icons-material';

type CartShopProps = {
	lng: 'es' | 'en';
};

export const CartShop = ({ lng }: CartShopProps) => {
	const { t } = useClientTranslation(lng, ['catalog', 'common']);
	const [openCartShop, setOpenCartShop] = useState<boolean>(false);
	const { setErrorNotification } = useNotification();
	const user = useSessionClient();
	const { setShowLogin } = useContextGeneral();

	const router = useRouter();

	const { countProductCartShop, cartShopId, setCountProductCartShop } =
		useContextCartShop();

	useEffect(() => {
		if (cartShopId) {
			getTotalProductsOfCartShop(cartShopId)
				.then((response) => {
					setCountProductCartShop(response.data);
				})
				.catch(() => {
					setErrorNotification(t('errorToGetTotalProductOfCartShop'));
				});
		}
	}, [cartShopId]);

	function handlerConfirm() {
		if (!user) {
			setShowLogin(true);
		} else {
			setOpenCartShop(false);
			router.push('/checkout');
		}
	}

	const list = () => (
		<Box
			sx={{
				width: { md: 500, xs: 300 },
				p: 1,
			}}
			role='presentation'
		>
			<Grid
				container
				sx={{
					mt: 2,
				}}
				spacing={1}
			>
				<Grid item xs={10}>
					<Typography variant='TitleSection' fontSize='1.4rem'>
						{t('cartShop')}
					</Typography>
				</Grid>
				<Grid item xs={2} display='flex' justifyContent='flex-end'>
					<Button
						color='secondary'
						variant='contained'
						sx={{
							borderRadius: '50%',
							p: 0,
							minWidth: '35px',
							height: '35px',
						}}
						onClick={() => setOpenCartShop(false)}
					>
						<Close />
					</Button>
				</Grid>
				<Grid item xs={12}>
					{openCartShop && (
						<ProductsOfCart
							lng={lng}
							onConfirm={() => handlerConfirm()}
							onCloseModalCart={() => setOpenCartShop(false)}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	);

	return (
		<CartShopProvider>
			<Box width='100%'>
				<Box
					sx={{
						'& > :not(style)': { m: 1 },
						position: 'fixed',
						right: { md: '5%', xs: '2%' },
						bottom: '10%',
						width: '50px',
						height: '50px',
						zIndex: 99,
					}}
				>
					<Fab
						color='secondary'
						aria-label={t('seeCartShop')}
						title={t('seeCartShop')}
						onClick={() => {
							setOpenCartShop(!openCartShop);
						}}
					>
						{countProductCartShop > 0 && (
							<Chip
								color='primary'
								label={countProductCartShop}
								sx={{
									position: 'absolute',
									top: '-13px',
									right: '-13px',
									fontSize: '18px',
								}}
							/>
						)}

						<ShoppingCartIcon
							sx={{
								color: (theme) => theme.palette.white?.main || 'white',
							}}
						/>
					</Fab>
				</Box>
				<Drawer
					anchor={'right'}
					open={openCartShop}
					onClose={() => setOpenCartShop(false)}
				>
					{list()}
				</Drawer>
			</Box>
		</CartShopProvider>
	);
};
