'use client';
import {
	Box,
	Button,
	ButtonGroup,
	IconButton,
	ListItem,
	ListItemText,
	TextField,
} from '@mui/material';
import { IProductCart } from '@negotium/models';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useClientTranslation } from '@negotium/common';

import { ImageWithFallback } from '../ImageWithFallback';

type ProductCartShopProps = {
	lng: 'es' | 'en';
	product: IProductCart;
	onDeleteProduct: (cartUniqueId: string) => void;
	onEditAmount: (cartUniqueId: string, amount: number) => void;
	isCheckout?: boolean;
	disabledButton?: boolean;
};

export const ProductCartShop = ({
	lng,
	product,
	onDeleteProduct,
	onEditAmount,
	isCheckout = false,
	disabledButton = false,
}: ProductCartShopProps) => {
	const { t } = useClientTranslation(lng, ['catalog']);

	const [amount, setAmount] = useState<number>(0);
	const [editAmountCallback, setAditAmountCallback] = useState<boolean>(false);

	useEffect(() => {
		if (product.cartUniqueId) {
			setAmount(product.amount);
		}
	}, [product.cartUniqueId]);

	useEffect(() => {
		if (editAmountCallback) {
			onEditAmount(product.cartUniqueId, amount);
			setAditAmountCallback(false);
		}
	}, [editAmountCallback]);

	const handleIncrement = () => {
		setAmount(amount + 1);
		setAditAmountCallback(true);
	};

	const handleDecrement = () => {
		if (amount > 1) {
			setAmount(amount - 1);
			setAditAmountCallback(true);
		} else {
			onDeleteProduct(product.cartUniqueId);
		}
	};

	function handlerBlur() {
		setAditAmountCallback(true);
	}

	return (
		<ListItem
			key={product.productId}
			secondaryAction={
				disabledButton ? null : (
					<IconButton
						edge='end'
						aria-label={t('deleteProductOfCartShop')}
						title={t('deleteProductOfCartShop')}
						onClick={() => onDeleteProduct(product.cartUniqueId)}
					>
						<DeleteIcon color='error' />
					</IconButton>
				)
			}
			sx={{
				background: isCheckout
					? '#efefef'
					: (theme) => theme.palette.white?.main || '',
				marginTop: '10px',
				borderRadius: 1,
			}}
		>
			<Box
				width={{
					xs: isCheckout ? '10%' : '25%',
					md: isCheckout ? '10%' : '25%',
				}}
				marginRight={2}
			>
				<ImageWithFallback
					src={product.productImage.url}
					fallbackImage='/static/facebook600x315.png'
					alt={product.productName[lng]}
					width={product.productImage.width}
					height={product.productImage.height}
					style={{ width: '100%', height: 'auto' }}
				/>
			</Box>
			<Box display='flex' flexDirection='column'>
				<ListItemText
					primary={product.productName[lng]}
					primaryTypographyProps={{
						variant: 'BodySection',
						fontSize: '1rem',
						letterSpacing: '1px',
						color: 'primary.main',
						margin: 0,
						lineHeight: '1.5rem',
						fontWeight: 'bold',
					}}
					secondary={`${t('priceTotal')}: ${product.priceTotal} ${
						product.currency
					}`}
					secondaryTypographyProps={{
						variant: 'BodySection',
						fontSize: '1rem',
						letterSpacing: '1px',
						color: 'primary.main',
						margin: 0,
						lineHeight: '1.5rem',
					}}
				/>
				<ButtonGroup
					variant='text'
					aria-label={t('changeAmountOfProduct')}
					title={t('changeAmountOfProduct')}
					sx={{
						height: '30px',
					}}
				>
					<Button
						variant='outlined'
						onClick={handleDecrement}
						aria-label={t('decrementAmount')}
						title={t('decrementAmount')}
						disabled={disabledButton}
					>
						-
					</Button>
					<TextField
						type='number'
						value={amount}
						InputProps={{
							readOnly: disabledButton,
						}}
						inputProps={{
							sx: {
								minHeight: '30px',
								py: 0,
								px: 1,
								borderRadius: 0,
								textAlign: 'center',
								justifyContent: 'center',
								display: 'flex',
								'-moz-appearance': 'textfield',
								'::-webkit-outer-spin-button': {
									'-webkit-appearance': 'none',
									margin: 0,
								},
								'::-webkit-inner-spin-button': {
									'-webkit-appearance': 'none',
									margin: 0,
								},
							},
						}}
						onBlur={disabledButton ? () => {} : () => handlerBlur()}
						onChange={
							disabledButton
								? () => {}
								: (e) => setAmount(Number(e.target.value))
						}
						sx={{
							width: '4em',
							borderRadius: 0,
							'&>*': {
								'&.MuiOutlinedInput-root': {
									borderRadius: 0,
								},
								fieldset: {
									borderColor: (theme) => theme.palette.primary.main,
								},
							},
						}}
					/>
					<Button
						variant='outlined'
						onClick={handleIncrement}
						aria-label={t('incrementAmount')}
						title={t('incrementAmount')}
						disabled={disabledButton}
					>
						+
					</Button>
				</ButtonGroup>
			</Box>
		</ListItem>
	);
};
