import React, { useEffect, useState } from 'react';
import { useNotification } from '../ErrorContext';
import { ICartShopItems } from '@negotium/models';
import { useContextCartShop } from '../ContextCartShop';
import {
	cancelCartShop,
	deleteProductCartShop,
	editAmountProductCartShop,
	getProductsByCartShopId,
} from '@negotium/libCustomer';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	CircularProgress,
	Grid,
	List,
	Typography,
} from '@mui/material';
import { LoadingRectangle } from '../Skeleton';
import { ProductCartShop } from '../CartShop/ProductCartShop';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';
import { useClientTranslation } from '@negotium/common';

type ProductsOfCartProps = {
	lng: 'es' | 'en';
	onConfirm: () => void;
	onCloseModalCart?: () => void;
	pageCheckout?: boolean;
	disabledButton?: boolean;
	isProcessing?: boolean;
};

export const ProductsOfCart = ({
	lng,
	pageCheckout = false,
	onConfirm,
	onCloseModalCart = () => {},
	disabledButton = false,
	isProcessing = false,
}: ProductsOfCartProps) => {
	const { t } = useClientTranslation(lng, ['catalog', 'common']);

	const { setErrorNotification, setSuccessNotification } = useNotification();

	const [dataCartShop, setDataCartShop] = useState<ICartShopItems | null>(null);

	const [isLoadingCartShop, setIsLoadingCartShop] = useState<boolean>(true);
	const { cartShopId, setCountProductCartShop } = useContextCartShop();

	const [processing, setProcessing] = useState<boolean>(false);

	function getProductsOfCart() {
		getProductsByCartShopId(cartShopId)
			.then((response) => {
				setDataCartShop(response.data);
				setIsLoadingCartShop(false);
			})
			.catch(() => {
				setIsLoadingCartShop(false);
				setErrorNotification(t('errorGetProductsOfCartShop'));
			});
	}

	useEffect(() => {
		if (isLoadingCartShop && cartShopId) {
			getProductsOfCart();
		}
	}, [isLoadingCartShop]);

	function handlerDeleteProductOfCart(cartUniqueId: string) {
		deleteProductCartShop(cartUniqueId)
			.then(() => {
				setSuccessNotification(t('deleteProductOfCartSuccessfully'));
				setIsLoadingCartShop(true);
			})
			.catch(() => {
				setErrorNotification(t('deleteProductOfCartError'));
			});
	}
	function handlerEditAmountProductOfCart(cartUniqueId: string, value: number) {
		editAmountProductCartShop(cartUniqueId, value)
			.then(() => {
				setSuccessNotification(t('editAmountProductOfCartSuccessfully'));
			})
			.catch(() => {
				setErrorNotification(t('editAmountProductOfCartError'));
			});
	}
	function handlerCancelCartShop() {
		if (dataCartShop?._id) {
			setProcessing(true);
			cancelCartShop(dataCartShop._id)
				.then(() => {
					setSuccessNotification(t('cartShopCanceledSuccessfully'));
					setIsLoadingCartShop(true);
					setProcessing(false);
					setCountProductCartShop(0);
					onCloseModalCart();
				})
				.catch(() => {
					setErrorNotification(t('cartShopCanceledError'));
					setProcessing(false);
				});
		}
	}

	if (isLoadingCartShop) {
		return [...Array(4).keys()].map(() => (
			<Grid container spacing={1} marginTop={0.5}>
				<Grid item xs={9}>
					<LoadingRectangle height={'5vh'} width={'100%'} />
				</Grid>
				<Grid item xs={3}>
					<LoadingRectangle height={'5vh'} width={'100%'} />
				</Grid>
			</Grid>
		));
	}

	if (!isLoadingCartShop && !dataCartShop) {
		return (
			<Grid container>
				<Grid item xs={12}>
					<Alert severity='info'>
						<AlertTitle>{t('noProductsInCartShop')}</AlertTitle>
					</Alert>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container rowSpacing={1}>
			<Grid item xs={12}>
				<List>
					{dataCartShop?.products?.map((product) => (
						<ProductCartShop
							isCheckout={pageCheckout}
							lng={lng}
							product={product}
							key={product.cartUniqueId}
							onDeleteProduct={(cartUniqueId: string) =>
								handlerDeleteProductOfCart(cartUniqueId)
							}
							onEditAmount={(cartUniqueId: string, amount: number) =>
								handlerEditAmountProductOfCart(cartUniqueId, amount)
							}
							disabledButton={disabledButton}
						/>
					))}
				</List>
			</Grid>
			<Grid item xs={12} display='flex'>
				<Typography
					variant='TitleSection'
					textAlign='center'
					fontSize='clamp(1rem, 3vw, 1.5rem)'
					margin={'8px 5px'}
					lineHeight={'clamp(1.5rem, 4vw, 2rem)'}
				>
					{`${t('priceTotal')}: `}
				</Typography>
				<Typography
					lineHeight={'clamp(1.5rem, 4vw, 2rem)'}
					variant='BodySection'
					textAlign='center'
					color='secondary.main'
					fontSize='clamp(1rem, 3vw, 1.5rem)'
					marginY={1}
				>
					{`${formatCurrency(
						dataCartShop?.priceTotal || 0,
						dataCartShop?.currency
					)} ${dataCartShop?.currency}`}
				</Typography>
			</Grid>
			{!disabledButton && (
				<Grid item xs={12}>
					<Box
						display='flex'
						flexDirection={
							pageCheckout ? { xs: 'column', sm: 'row' } : 'column'
						}
						justifyContent={
							pageCheckout
								? {
										sm: 'flex-end',
								  }
								: 'center'
						}
					>
						<Button
							color='error'
							variant='contained'
							title={t('cancel', { ns: 'common' })}
							disabled={processing || isProcessing}
							onClick={() => handlerCancelCartShop()}
						>
							{processing ? (
								<CircularProgress />
							) : (
								t('cancel', { ns: 'common' })
							)}
						</Button>
						<Button
							color='secondary'
							variant='contained'
							title={t('confirm', { ns: 'common' })}
							disabled={processing || isProcessing}
							onClick={() => onConfirm()}
							sx={{
								marginLeft: pageCheckout ? 1 : 0,
								...(!pageCheckout && {
									mt: 1,
								}),
							}}
						>
							{processing || isProcessing ? (
								<CircularProgress color='secondary' />
							) : (
								t('confirm', { ns: 'common' })
							)}
						</Button>
					</Box>
				</Grid>
			)}
		</Grid>
	);
};
